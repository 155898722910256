.slashes {
  display: flex;

  .slash {
    height: 100%;
    transform: skew(-25deg);
  }
}


.head {
  padding: em(260 0 170, $fz);

  &__wrapper {
    max-width: em(1214, $fz);
    width: 100%;
    padding: em(0 30, $fz);
    margin: 0 auto em(90, $fz);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    display: flex;
    align-items: center;
    $fz: 70;
    font-size: em($fz, $fz_base);
    line-height: 1.5;
  }

  &__slashes {
    $fz: 70;
    height: em(46, $fz);
    margin-right: em(25, $fz);
    margin-bottom: em(-2, $fz);
  }

  &__slash {
    $fz: 70;
    width: em(12, $fz);
    background-color: hsl(0, 0%, 100%);
    margin-right: em(12, $fz);
  }

  &__desc {
    margin-top: em(185, $fz);
  }

  &__arrow {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    padding: em(10 15, $fz);
    animation: opacity 2s infinite;

    &:hover {
      box-shadow: 0 0 em(3, $fz) hsla(0, 0%, 100%, 0.9);
    }
  }

  &__svg {
    stroke: $white;
    width: em(14, $fz);
    height: em(25, $fz);
  }
}

@keyframes border-top {
  0% {
    width: 0;
    height: 0;
  }
  50% {
    border-top-color: $white;
    width: 100%;
    height: 0;
  }
  100% {
    border-top-color: $white;
    border-right-color: $white;
    height: 100%;
    width: 100%;
  }
}

@keyframes border-bottom {
  0% {
    width: 0;
    height: 0;
  }

  50% {
    border-bottom-color: $white;
    width: 100%;
    height: 0;
  }

  100% {
    border-bottom-color: $white;
    border-left-color: $white;
    height: 100%;
    width: 100%;
  }
}

.head-desc {
  position: relative;
  $fz: 19;
  font-size: em($fz, $fz_base);
  padding: em(36 38 38 46, $fz);
  max-width: em(362, $fz);
  line-height: 1.5;

  &::before,
  &::after {
    content: '';
    position: absolute;
    border: 2px solid transparent;
  }

  &::before {
    top: 0;
    left: 0;
    animation: border-top .8s linear .3s forwards;
  }

  &::after {
    bottom: 0;
    right: 0;
    animation: border-bottom .8s linear 1.1s forwards;
  }
}

@media only screen and (max-width: $media-xl) {
  .head {
    &__title {
      $fz: 60;
      font-size: em($fz, $fz_base);
    }
  }
}

@media only screen and (max-width: $media-l) {
  .head {
    padding: em(180 0 220, $fz);

    &__wrapper {
      margin-bottom: 0;
    }

    &__title {
      $fz: 50;
      font-size: em($fz, $fz_base);
    }

    &__desc {
      margin-left: em(15, $fz);
    }

    &__arrow {
      display: none;
    }
  }

  .head-desc {
    $fz: 18;
    font-size: em($fz, $fz_base);
    padding: em(25 30, $fz) ;
  }
}

@media only screen and (max-width: $media-m) {
  .head {
    padding: em(150 0 120, $fz);

    &__wrapper {
      flex-direction: column;
    }

    &__titles {
      margin-left: em(-100, $fz);
    }

    &__desc {
      $fz: 18;
      margin-top: em(50, $fz);
      margin-right: em(-100, $fz);
    }
  }
}

@media only screen and (max-width: $media-s) {
  .head {
    padding: em(130 0 110, $fz);

    &__desc {
      margin-top: em(50, $fz);
      margin-right: 0;
    }

    &__title {
      $fz: 30;
      font-size: em($fz, $fz_base);
    }
  }

  .head-desc {
    $fz: 16;
    font-size: em($fz ,$fz_base);
    line-height: 1.4;
    padding: em(20 30 20 25, $fz);
    align-self: flex-end;
  }
}

@media only screen and (max-width: $media-xs) {
  .head {
    &__wrapper {
      padding: em(0 20, $fz);
      align-items: center;
    }

    &__titles {
      margin-left: 0;
    }

    &__desc {
      margin-right: em(-22, $fz);
    }

    &__title {
      $fz: 28;
      font-size: em($fz, $fz_base);
    }
  }

  .head-desc {
    $fz: 14;
    font-size: em($fz, $fz_base);
  }
}

@keyframes opacity {
  50% {
    box-shadow: 0 0 em(3, $fz) $white;
  }
}
