.price {
  padding-bottom: em(220, $fz);

  &__wrapper {
    margin-bottom: em(70, $fz);
  }

  &__composition {
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }

  &__component {
    margin-right: em(45, $fz);

    &:last-child {
      margin: em(184 0 0, $fz);
    }
  }
}

.price-component {
  display: flex;
  align-items: flex-end;

  &__block {
    position: relative;
    background-color: $white;
    width: em(250, $fz);
    height: em(250, $fz);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__svg {
    fill: $main-color;

    &--size119 {
      width: em(119, $fz);
      height: em(119, $fz);
    }

    &--size137 {
      width: em(137, $fz);
      height: em(134, $fz);
    }
  }

  &__line {
    position: absolute;
    left: 99%;
    top: 99%;
    width: em(253, $fz);
    height: em(64, $fz);
  }

  &__path {
    &.active {
      stroke: $white;
      stroke-dashoffset: 299;
      stroke-dasharray: 299;
      animation: reveal 1s ease-in-out forwards;
    }
  }

  &__desc {
    display: flex;
    flex-direction: column;
    margin: em(0 0 -60 28, $fz);
    padding: em(0 12 0 15, $fz);
    position: relative;

    &::before {
      display: none;
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: em(2, $fz);
      background-color: $white;
      transition: width 1.2s ease-in-out;
    }

    &.active::before {
      width: 100%;
    }
  }

  &__percent {
    $fz: 48;
    font-size: em($fz, $fz_base);
    font-weight: 700;
    margin-bottom: em(5, $fz);
  }

  &__text {
    $fz: 16;
    font-size: em($fz, $fz_base);
    font-weight: 500;
    margin-bottom: em(20, $fz);
  }
}

@keyframes reveal {
  to {
    stroke-dashoffset: 0;
  }
}

@media only screen and (max-width: $media-xl) {
  .price {
    padding-bottom: em(200, $fz);
  }

  .price-component {
    &__block {
      width: em(200, $fz);
      height: em(200, $fz);
    }
  }
}

@media only screen and (max-width: $media-l) {
  .price {
    padding-bottom: em(150, $fz);

    &__composition {
      flex-direction: column;
      align-items: center;
    }
  }

  .price-component {
    &:first-child {
      position: relative;
      left: em(-90, $fz);
    }

    &:last-child {
      position: relative;
      margin-top: em(60, $fz);
      right: em(-90, $fz);
    }

    &__block {
      width: em(170, $fz);
      height: em(170, $fz);
    }

    &__svg {
      &--size119 {
        width: em(90, $fz);
        height: em(90, $fz);
      }

      &--size137 {
        width: em(101, $fz);
        height: em(99, $fz);
      }
    }

    &__line {
      display: none;
    }

    &__desc {
      margin: 0;
      padding: em(0 0 25 15, $fz);

      &::before {
        display: block;
      }
    }

    &__percent {
      $fz: 40;
      font-size: em($fz, $fz_base);
    }

    &__text {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: $media-m) {
  .price {
    padding-bottom: em(100, $fz);

    &__composition {
      flex-direction: column;
      align-items: center;
    }

    &__component {
      &:first-child {
        left: em(-60, $fz);
      }

      &:last-child {
        right: em(-60, $fz);
      }
    }
  }
}

@media only screen and (max-width: $media-s) {
  .price {
    padding-bottom: em(80, $fz);

    &__composition {
      align-items: flex-start;
    }

    &__component {
      &:first-child {
        left: 0;
      }

      &:last-child {
        right: 0;
      }
    }
  }
}

@media only screen and (max-width: $media-xs) {
  .price-component {
    &__block {
      width: em(113, $fz);
      height: em(113, $fz);
    }

    &__svg {
      &--size119 {
        width: em(55, $fz);
        height: em(55, $fz);
      }

      &--size137 {
        width: em(63, $fz);
        height: em(63, $fz);
      }
    }

    &__desc {
      border-bottom: none;
      padding-bottom: em(15, $fz);
    }

    &__percent {
      $fz: 30;
      font-size: em($fz, $fz_base);
    }

    &__text {
      $fz: 14;
      font-size: em($fz, $fz_base);
    }
  }
}

