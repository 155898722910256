@font-face {
  font-family: "Futura";
  src: url('../fonts/FuturaPT-Medium.ttf') format("truetype"),
  url('../fonts/FuturaPT-Medium.woff') format("woff"),
  url('../fonts/FuturaPT-Medium.woff2') format("woff2"),
  url('../fonts/FuturaPT-Medium.eot') format("eot");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Futura";
  src: url('../fonts/FuturaPT-Bold.ttf') format("truetype"),
  url('../fonts/FuturaPT-Bold.woff') format("woff"),
  url('../fonts/FuturaPT-Bold.woff2') format("woff2"),
  url('../fonts/FuturaPT-Bold.eot') format("eot");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Futura";
  src: url('../fonts/FuturaPT-Heavy.ttf') format("truetype");
  font-weight: 900;
  font-style: normal;
}
//@font-face {
//  font-family: "Roboto";
//  src: url('../fonts/Roboto-Bold.ttf') format("truetype"),
//  url('../fonts/Roboto-Bold.woff') format("woff");
//  font-weight: 700;
//  font-style: normal;
//}
//@font-face {
//  font-family: "SecularOne";
//  src: url('../fonts/SecularOne-Regular.ttf') format("truetype"),
//  url('../fonts/SecularOne-Regular.woff') format("woff");
//  font-weight: 800;
//  font-style: normal;
//}
