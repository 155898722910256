.header {
  padding: em(42 86 0 57, $fz);

  &__wrapper {
    max-width: em(1780, $fz);
    display: flex;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    margin: em(4 auto 0 0, $fz);
  }

  &__svg {
    width: em(92, $fz);
    height: em(25, $fz);
    margin-bottom: em(4, $fz);
    fill: $white;
  }

  &__company {
    $fz: 8;
    font-size: em($fz, $fz_base);
  }
}


.navbar {
  &.active {
    &::after {
      content: '';
      position: absolute;
      top: em(62, $fz);
      left: 0;
      right: 0;
      bottom: 0;
      background-color: hsla(0, 0%, 0%, 0.3);
      z-index: -1;
    }
  }

  &__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.active {
      position: absolute;
      right: 0;
      top: em(62, $fz);
      display: flex;
      flex-direction: column;
      background-color: $main-color;
      width: 100%;
      animation: grow 300ms ease-in-out forwards;
      transform-origin: top center;

      .navbar__item {
        width: 100%;
        height: 100%;
      }

      .navbar__link {
        width: 100%;
        height: 100%;
        padding: em(15, $fz);
        text-align: center;

        &:hover {
          background-color: hsl(215, 90%, 45%);
        }
      }
    }
  }

  &__item {
    height: 100%;
    margin-right: em(60, $fz);

    &:last-of-type {
      margin: em(0 0 0 65, $fz);
    }
  }

  &__link {
    $fz: 18;
    font-size: em($fz, $fz_base);
    font-weight: 500;
    min-width: em(92, $fz);
    text-align: center;
    display: inline;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: em(-3, $fz);
      left: 0;
      height: em(2, $fz);
      background-color: $white;
      width: 0;
      transition: width .5s ease;
    }

    &:hover::after {
      width: 101%;
    }

    &--bold {
      font-weight: 700;
    }
  }

  &__burger {
    display: none;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: em(13, $fz);
    width: em(21, $fz);
    cursor: pointer;
    transition: transform .1s;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &.active {
      span {
        &:nth-child(1) {
          transform: rotate(45deg) translate(em(3, $fz), em(3, $fz));
        }

        &:nth-child(2) {
          display: none;
        }

        &:nth-child(3) {
          transform: rotate(-45deg) translate(em(5, $fz), em(-5, $fz));
        }
      }
    }

    span {
      background-color: #fff;
      height: em(2, $fz);
      width: 100%;
    }
  }
}

@keyframes grow {
  0% {
    transform: scaleY(0);
  }

  80% {
    transform: scaleY(1.1);
  }

  100% {
    transform: scaleY(1);
  }
}

@media only screen and (max-width: $media-xl) {
  .header {
    padding: em(42 30 0, $fz);
  }

  .navbar__item {
    margin-right: em(40, $fz);
  }
}

@media only screen and (max-width: $media-l) {
  .header {
    padding: em(25 0 0, $fz);
    margin: em(0 20, $fz);

    &__logo {
      margin-top: 0;
    }
  }

  .navbar__item {
    margin-right: em(25, $fz);

    &:last-child {
      margin-left: em(40, $fz);
    }
  }
}

@media only screen and (max-width: $media-m) {
  .navbar {
    z-index: 5;
  }

  .navbar__list {
    display: none;
  }

  .navbar__burger {
    display: flex;
  }

  .navbar__item {
    margin-right: 0;
  }
}

@media only screen and (max-width: $media-s) {
  .header {
    &__svg {
      width: em(60, $fz);
      height: em(16, $fz);
    }

    &__company {
      display: none;
    }
  }

  .navbar__list.active {
    top: em(50, $fz);
  }

}

@media only screen and (max-width: $media-xs) {
  .navbar.active {
    .navbar__list {
      height: calc(100vh - 45px);
      justify-content: flex-end;
      padding-bottom: em(30, $fz);
    }

    .navbar__item {
      height: auto;

      &:last-child {
        margin-top: em(140, $fz);
      }
    }

    .navbar__link {
      $fz: 18;
      padding: em(10 20, $fz);
      text-align: left;
    }
  }
}



