.services {
  padding-bottom: em(245, $fz);

  &__wrapper {
    margin-bottom: em(120, $fz);
  }

  &__list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: em(-9, $fz);
  }

  &__item {
    margin: em(9, $fz);
  }
}

.services-item {
  display: flex;
  flex: 1 0 21%;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  padding: em(15 15 30 31, $fz);
  min-height: em(196, $fz_base);
  cursor: pointer;
  overflow: hidden;

  &:nth-child(2) {
    padding-left: em(34, $fz);
  }

  &:nth-child(3) {
    padding-left: em(38, $fz);
  }

  &:last-child {
    padding-left: em(43, $fz);
  }

  &__slashes {
    height: em(21, $fz);
    margin-left: auto;
  }

  &__slash {
    width: em(6, $fz);
    background-color: $main-color;
    margin-right: em(6, $fz);
  }

  &__title{
    $fz: 17;
    font-size: em($fz, $fz_base);
    font-weight: 700;
    color: $main-color;
    line-height: 1.5;
    position: relative;

    &--transparent {
      color: transparent;
    }

    &::before,
    &::after {
      content: attr(data-hover-roll);
      color: $main-color;
      position: absolute;
      left: 0;
      transition: opacity .5s, transform .5s;
    }

    &::after {
      top: 100%;
      opacity: 0;
    }

    &--w215 {
      max-width: em(215, $fz);
    }

    &--w180 {
      max-width: em(180, $fz);
    }
  }

  &:hover .services-item__title{
    &::before {
      opacity: 0;
      transform: translateY(-100%);
    }
    &::after {
      opacity: 1;
      transform: translateY(-100%);
    }
  }
}

.services-popup {
  position: absolute;
  width: calc(50% - 18px);
  height: calc(100% - 18px);
  padding: em(30 30 35 47, $fz);
  background-color: $white;
  box-shadow: 0 0 em(60, $fz) $main-color;
  transition: transform .5s, opacity .1s;
  transform: scale(0);
  z-index: 100;

  &.active {
    transform: scale(1);
  }

  &--pos-1 {
    left: em(9, $fz);
    top: em(9, $fz);
    transform-origin: top left;
  }

  &--pos-2 {
    right: calc(50% + 9px);
    top: em(9, $fz);
    transform-origin: top right;
  }

  &--pos-3 {
    left: calc(50% + 9px);
    top: em(9, $fz);
    transform-origin: top left;
  }

  &--pos-4 {
    right: em(9, $fz);
    top: em(9, $fz);
    transform-origin: top right;
  }

  &--pos-5 {
    left: em(9, $fz);
    bottom: em(9, $fz);
    transform-origin: bottom left;
  }

  &--pos-6 {
    right: calc(50% + 9px);
    bottom: em(9, $fz);
    transform-origin: bottom right;
  }

  &--pos-7 {
    left: calc(50% + 9px);
    bottom: em(9, $fz);
    transform-origin: bottom left;
  }

  &--pos-8 {
    right: em(9, $fz);
    bottom: em(9, $fz);
    transform-origin: bottom right;
  }

  &__title {
    $fz: 17;
    font-size: em($fz, $fz_base);
    font-weight: 700;
    color: $main-color;
    line-height: 1.5;
    position: relative;
    margin-bottom: em(20, $fz);
    max-width: 70%;
  }

  &__desc {
    $fz: 16;
    font-size: em($fz, $fz_base);
    font-weight: 500;
    color: $main-color;
    line-height: 1.45;
  }

  &__close {
    width: em(20, $fz);
    height: em(20, $fz);
    margin-top: em(-15, $fz);
    cursor: pointer;
    margin-left: auto;
    position: relative;
    left: em(15, $fz);

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 101;
    }

    span {
      position: relative;
      width: em(5, $fz);
      height: 100%;
      background-color: $main-color;
      display: inline-block;

      &:nth-child(1) {
        transform: rotate(45deg);
        left: em(7, $fz);
      }

      &:nth-child(2) {
        transform: rotate(-45deg);
        right: em(1, $fz);
      }
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(0);
  }
  1% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@media only screen and (max-width: $media-xl) {
  .services {
    padding-bottom: em(200, $fz);

    &__wrapper {
      margin-bottom: em(80, $fz);
    }
  }

  .services-item {
    &:nth-child(1n) {
      padding-left: em(30, $fz);
    }
  }

  .services-popup {
    padding-left: em(25, $fz);

    &__title {
      line-height: 1.3;
    }

    &__desc {
      line-height: 1.2;
    }
  }
}

@media only screen and (max-width: $media-l) {
  .services {
    padding-bottom: em(150, $fz);
  }

  .services-item {
    flex-basis: 40%;
  }

  .services-popup {
    width: calc(100% - 18px);
    height: calc(50% - 18px);

    &--pos-2 {
      right: em(9, $fz);
    }

    &--pos-3 {
      left: em(9, $fz);
      transform-origin: bottom left;
    }

    &--pos-4 {
      transform-origin: bottom right;
    }

    &--pos-5 {
      transform-origin: top left;
    }

    &--pos-6 {
      right: em(9, $fz);
      transform-origin: top right;
    }

    &--pos-7 {
      left: em(9, $fz);
    }
  }
}

@media only screen and (max-width: $media-m) {
  .navbar {
    &__item {
      &:last-child {
        margin-left: 0;
      }
    }
    &__link {
      display: inline-block;
    }
  }

  .services {
    padding-bottom: em(100, $fz);

    &__wrapper {
      margin-bottom: em(60, $fz);
    }
  }

  .services-item__title {
    line-height: 1.3;
  }

  .services-popup {
    padding-top: em(20, $fz);

    &__title {
      margin-bottom: em(10,$fz);
      max-width: 85%;
    }

    &__close {
      top: em(10, $fz);
    }

    &__desc {
      line-height: 1.1;
    }
  }
}

@media only screen and (max-width: $media-s) {
  .services {
    padding-bottom: em(80, $fz);

    &__wrapper {
      margin-bottom: em(50, $fz);
    }
  }

  .services-item {
    min-height: em(160, $fz);
    padding-bottom: em(20, $fz);
    padding-top: em(10, $fz);

    &:nth-child(1n) {
      padding-left: em(20, $fz);
    }

    &__slashes {
      position: relative;
      top: em(-5, $fz);
      right: em(-5, $fz);
    }
  }
}

@media only screen and (max-width: $media-xs) {
  .services {
    &__wrapper {
      .section__desc {
        display: none;
      }
    }

    &__row {
      flex-direction: column;
      margin-bottom: em(18, $fz);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .services-item {
    $fz: 14;
    flex: 0 1 100%;
    min-height: em(101, $fz);
    padding-bottom: em(6, $fz);

    &:last-child {
      margin-bottom: 0;
    }

    &__slashes {
      top: em(-8, $fz);
      order: 2;
      align-self: flex-end;
    }

    &__title--w215,
    &__title--w180 {
      max-width: 100%;
    }
  }

  .services-popup {
    height: calc(50% - 14px);

    &--pos-5,
    &--pos-6,
    &--pos-7,
    &--pos-8 {
      bottom: 0;
    }
  }
}

@media only screen and (max-width: '380px') {
  .services-popup {
    padding: em(15 20 15 15, $fz);

    &__title {
      $fz: 16;
      font-size: em($fz, $fz_base);
      max-width: 95%;
      line-height: 1.2;
    }

    &__close {
      top: em(5, $fz);
    }

    &__desc {
      line-height: 1;
    }
  }
}
