.footer {
  padding-bottom: em(200, $fz);

  &__wrapper {
    display: flex;
    justify-content: space-between;
  }

  &__address {
    display: flex;
    flex-direction: column;

    span {
      $fz: 24;
      font-size: em($fz, $fz_base);
    }
  }

  &__contacts {
    display: flex;
    flex-direction: column;
  }

  &__link,
  &__text {
    $fz: 30;
    font-size: em($fz, $fz_base);
    font-weight: 700;
    line-height: 1.5;
  }

  &__hr {
    display: flex;
    flex-direction: column;
  }

  &__answer {
    $fz: 24;
    font-size: em($fz, $fz_base);
  }
}

@media only screen and (max-width: $media-xl) {
  .footer {
    padding-bottom: em(150, $fz);

    &__address span,
    &__answer{
      $fz: 22;
      font-size: em($fz, $fz_base);
    }

    &__link {
      $fz: 28;
      font-size: em($fz, $fz_base);
    }
  }
}

@media only screen and (max-width: $media-l) {
  .footer {
    &__wrapper {
      flex-direction: column;
      align-items: center;
    }

    &__cotacts {
      order: 1;
    }

    &__address,
    &__hr {
      order: 2;
    }

    &__address,
    &__contacts,
    &__hr {
      align-items: center;
    }

    &__address {
      margin: em(40 0, $fz);
    }
  }
}

@media only screen and (max-width: $media-s) {
  .footer {
    padding-bottom: em(100, $fz);

    &__address,
    &__contacts,
    &__hr {
      width: 100%;
    }
  }
}

@media only screen and (max-width: $media-xs) {
  .footer {
    padding-bottom: em(80, $fz);

    &__address span,
    &__link,
    &__text,
    &__answer {
      $fz: 16;
      font-size: em($fz, $fz_base);
    }

    &__address {
      margin: em(30, $fz);
    }
  }
}
