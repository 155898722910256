html {
  scroll-behavior: smooth;
}

body {
  position: relative;
  background: $main-color;
  font-family: 'Futura', sans-serif;
  font-size: 14px;
  color: $white;
  font-weight: 500;
  min-width: em(320, $fz);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;

  &.overflow {
    overflow: hidden;
  }
}

img {
  max-width: 100%;
  height: auto;
}

section {
  position: relative;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

br {
  font-size: 0;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

.container {
  max-width: em(1474, $fz);
  width: 100%;
  padding: 0 em(15, $fz);
  margin: 0 auto;
}

input {
  outline: none;
}

a {
  color: inherit;
}

picture {
  display: flex;
}


