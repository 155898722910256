/**********************
* typography
**********************/
h1,
h2,
h3,
h4,
h5,
h6,
p,
hr {
  margin: 0;
}

h1 {
}

h2 {
}

h3 {
}

h4 {

}

h5 {

}

h6 {

}

p {

}

a {
  display: inline-block;
  text-decoration: none;
}

a:hover,
a:focus,
a:active {

}
