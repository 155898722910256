.bg {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;

  &__elems {
    display: flex;
    position: relative;
  }

  &__slashes {
    height: em(257, $fz);
    margin-right: em(85, $fz);
  }

  &__slash {
    width: em(68, $fz);
    background-color: $main-color;
    margin-right: em(68, $fz);
    animation: move-slash 4.5s ease-in-out infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }
  }

  &__elem {
    $fz: 288;
    font-size: em($fz, $fz_base);
    font-weight: 900;
    color: $main-color;
    //text-shadow: em(11, $fz) em(24, $fz) em(56, $fz) hsl(215, 100%, 39%);
    text-transform: uppercase;
    animation: move 4.5s ease-in-out infinite;

    &:nth-child(2) {
      animation-delay: 0.5s;
    }

    &:nth-child(3) {
      animation-delay: 1s;
    }

    &:nth-child(4) {
      animation-delay: 1.5s;
    }

    &:nth-child(5) {
      animation-delay: 2s;
    }

    &:nth-child(6) {
      animation-delay: 2.5s;
    }

    &:nth-child(7) {
      animation-delay: 3s;
    }

    &:nth-child(8) {
      animation-delay: 3.5s;
    }

    &--size350 {
      $fz: 350;
      font-size: em($fz, $fz_base);
    }
  }

  &__head {
    top: em(184, $fz);
    left: em(320, $fz);
    margin-bottom: em(270, $fz);

    .bg__elem {

      &:nth-last-child(3) {
        $fz: 350;
        margin-top: em(70, $fz);
        margin-right: em(200, $fz);
      }

      &:nth-last-child(2)  {
        $fz: 350;
        margin-top: em(270, $fz);
        margin-right: em(200, $fz);
      }

      &:nth-last-child(1)  {
        $fz: 350;
        margin-top: em(150, $fz);
      }
    }
  }

  &__about {
    left: em(-500, $fz);
    margin-bottom: em(430, $fz);

    .bg__elem {

      &:nth-child(1) {
        $fz: 288;
        margin-right: em(100, $fz);
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        $fz: 288;
        margin-top: em(100, $fz);
      }
    }
  }

  &__services {
    left: em(120, $fz);
    margin-bottom: em(430, $fz);

    .bg__elem {
      &:nth-child(2) {
        $fz: 288;
        margin-right: em(30, $fz);
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        $fz: 288;
        margin-top: em(120, $fz);
      }

      &:nth-child(5) {
        $fz: 288;
        margin-right: em(420, $fz);
      }

      &:nth-child(6) {
        $fz: 288;
        margin-top: em(350, $fz);
      }
    }
  }

  &__vacancies {
    left: em(-50, $fz);
    margin-bottom: em(60, $fz);

    .bg__elem {

      &:nth-child(5) {
        $fz: 288;
        margin-right: em(30, $fz);
      }

      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        $fz: 288;
        margin-top: em(190, $fz);
      }
    }
  }

  &__price {
    margin-bottom: auto;
  }
}


.head-bg {
  top: em(100, $fz);
  left: em(521, $fz);

  &__letters {
    $fz: 350;
    font-size: em($fz, $fz_base);
  }
}

@keyframes move {
  0% {
    transform: translate(-35%, 0);
  }
  50% {
    $fz: 288;
    text-shadow: em(11, $fz) em(24, $fz) em(56, $fz) hsl(215, 100%, 39%);
  }
  100% {
    transform: translate(35%, 0);
  }
}

@keyframes move-slash {
  0% {
    transform: translate(-55%, 0) skew(-25deg);
  }
  50% {
    box-shadow: em(11, $fz) em(24, $fz) em(56, $fz) hsla(215, 100%, 39%, 1);
  }
  100% {
    transform: translate(55%, 0) skew(-25deg);
  }
}

@media only screen and (max-width: $media-xl) {
  .bg {
    &__elem {
      $fz: 240;
      font-size: em($fz, $fz_base);

      &--size350 {
        $fz: 300;
        font-size: em($fz, $fz_base);
      }
    }

    &__slashes {
      height: em(220, $fz);
      margin-right: em(30, $fz);
    }

    &__head {
      top: em(150, $fz);
      left: em(100, $fz);
      margin-bottom: em(380, $fz);

      .bg__elem {
        &:nth-last-child(3) {
          $fz: 300;
          margin-right: em(50, $fz);
        }

        &:nth-last-child(2)  {
          $fz: 300;
          margin-right: em(50, $fz);
        }
      }
    }

    &__about {
      left: em(-150, $fz);
      margin-bottom: em(430, $fz);
    }

    &__services {
      left: em(-50, $fz);
      margin-bottom: em(450, $fz);
    }

    &__vacancies {
      left: em(-10, $fz);
      margin-bottom: em(160, $fz);
    }
  }
}

@media only screen and (max-width: $media-l) {
  .bg {
    &__elem {
      line-height: 0.9;
      position: relative;
    }

    &__slashes {
      height: em(180, $fz);
      position: relative;
      left: em(-200, $fz);
    }

    &__slash {
       width: em(60, $fz);
       margin-right: em(60, $fz);
    }

    &__head {
      flex-direction: column;
      top: em(120, $fz);
      margin-bottom: em(-150, $fz);

      .bg__elem {
        &:nth-last-child(3) {
          $fz: 240;
          left: em(200, $fz);
          top: em(-100, $fz);
        }

        &:nth-last-child(2)  {
          $fz: 240;
          margin-top: 0;
          top: em(-150, $fz);
          right: em(150, $fz);
        }

        &:nth-last-child(1)  {
          $fz: 240;
          margin-top: 0;
          top: em(-200, $fz);
          left: em(150, $fz);
        }
      }
    }

    &__services {
      left: em(-60, $fz);
      margin-bottom: em(750, $fz);
    }

    &__vacancies {
      left: em(80, $fz);
      margin-bottom: em(200, $fz);
    }

    &__footer {
      left: em(120, $fz);

      .bg__elem {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          $fz: 240;
          position: relative;
          top: em(-200, $fz);
        }

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          $fz: 240;
          position: relative;
          left: em(-100, $fz);
        }
      }
    }
  }
}

@media only screen and (max-width: $media-m) {
  .bg {
    &__elem {
      $fz: 200;
      font-size: em($fz, $fz_base);
    }

    &__slashes {
      height: em(120, $fz);
      position: relative;
      left: em(-200, $fz);
    }

    &__slash {
      width: em(45, $fz);
      margin-right: em(45, $fz);
    }

    &__head {
      flex-direction: column;
      top: em(120, $fz);
      margin-bottom: em(120, $fz);
    }

    &__about {
      left: em(-20, $fz);
      margin-bottom: em(450, $fz);
    }

    &__services {
      left: em(80, $fz);
      margin-bottom: em(800, $fz);

      .bg__elem:nth-child(5) {
        $fz: 200;
        margin-right: em(60, $fz);
      }
    }

    &__vacancies {
      left: em(180, $fz);
      margin-bottom: em(300, $fz);
    }
  }
}

@media only screen and (max-width: $media-s) {
  .bg {
    &__elem {
      $fz: 150;
      font-size: em($fz, $fz_base);
    }

    &__slashes {
      height: em(90, $fz);
      position: relative;
      left: em(-150, $fz);
    }

    &__slash {
      width: em(30, $fz);
      margin-right: em(30, $fz);
    }

    &__head {
      top: em(80, $fz);
      margin-bottom: em(120, $fz);
    }

    &__about {
      left: em(-20, $fz);
      margin-bottom: em(650, $fz);
    }

    &__services {
      left: em(-10, $fz);
      margin-bottom: em(1450, $fz);
    }

    &__vacancies {
      display: none;
    }

    &__footer {
      .bg__elem {
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          $fz: 200;
          position: relative;
          top: em(-300, $fz);
          left: em(200, $fz);
        }

        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8) {
          $fz: 200;
          position: relative;
          left: em(-500, $fz);
        }
      }
    }
  }
}

@media only screen and (max-width: $media-xs) {
  .bg {
    &__elem {
      $fz: 96;
      font-size: em($fz, $fz_base);
    }

    &__slashes {
      height: em(83, $fz);
      position: relative;
      left: em(-150, $fz);
    }

    &__slash {
      width: em(22, $fz);
      margin-right: em(22, $fz);
    }

    &__head {
      top: em(50, $fz);
      left: em(110, $fz);
      margin-bottom: em(250, $fz);

      .bg__elem {
        &:nth-last-child(3) {
          $fz: 96;
          left: em(0, $fz);
          top: em(-10, $fz);
        }

        &:nth-last-child(2)  {
          $fz: 96;
          margin-top: 0;
          top: em(20, $fz);
          right: em(120, $fz);
        }

        &:nth-last-child(1)  {
          $fz: 96;
          margin-top: 0;
          top: em(100, $fz);
          left: 0;
        }
      }
    }

    &__about {
      left: em(50, $fz);
      margin-bottom: em(2250, $fz);

      .bg__elem {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          $fz: 96;
          top: em(30, $fz);
        }
      }
    }

    &__services {
      display: none;
    }

    &__vacancies {
      display: none;
    }

    &__price {
      left: em(50, $fz);
    }

    &__footer {
      left: em(50, $fz);
    }
  }
}
