.vacancies {
  padding-bottom: em(215, $fz);

  &__list {
    max-width: em(950, $fz);
    width: em(950, $fz);
    flex-grow: 1;
    margin: em(-20 0 0 auto, $fz);
  }
}

.vacancies-item {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1;
  }

  &__name {
    $fz: 18;
    font-size: em($fz, $fz_base);
    padding: em(18 0, $fz);
    font-weight: 700;
    line-height: 1.4;
    cursor: pointer;
    position: relative;
    color: transparent;

    &::before,
    &::after {
      content: attr(data-hover-roll);
      position: absolute;
      left: 0;
      color: $white;
      transition: opacity .3s, transform .3s;
    }

    &::after {
      opacity: 0;
      transform: translateY(100%);
    }

    &:hover {
      &::before {
        opacity: 0;
        transform: translateY(-100%);
      }

      &::after {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  &__btn {
    cursor: pointer;
    position: relative;
    height: em(30, $fz);
    width: em(30, $fz);

    &::before {
      content: '+';
      $fz: 30;
      font-size: em($fz, $fz_base);
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
    }

    &.active::before {
      content: '–	';
    }
  }

  &__line {
    height: em(2, $fz);
    background-color: $white;
    width: 0;
    transition: width 1.5s ease-in-out .5s;
  }
}

.dropdown {
  padding: em(35 35 35 45, $fz);
  background-color: $white;
  display: flex;
  position: absolute;
  transform: scaleY(0);
  transition: all .3s;

  &.active {
    position: relative;
    animation: grow .5s ease-in-out forwards;
    transform-origin: top center;

    .dropdown__col {
      opacity: 1;
    }
  }

  &.close {
    animation: growDown .2s ease-in-out;
    transform-origin: top center;
  }

  &__col {
    opacity: 0;
    margin-right: em(30, $fz);
    transition: opacity .3s ease-in-out .3s;

    &:last-child {
      margin-right: 0;
    }
  }

  &__title {
    $fz: 16;
    font-size: em($fz, $fz_base);
    font-weight: 700;
    color: $main-color;
    margin-bottom: em(25, $fz);
  }

  &__desc {
    $fz: 16;
    font-size: em($fz, $fz_base);
    font-weight: 500;
    color: $main-color;
    line-height: 1.3;
  }
}

@keyframes growDown {
  0% {
    transform: scaleY(1);
  }
  100% {
    transform: scaleY(0);
  }
}

@media only screen and (max-width: $media-xl) {
  .vacancies {
    padding-bottom: em(200, $fz);
  }
}

@media only screen and (max-width: $media-l) {
  .vacancies {
    padding-bottom: em(150, $fz);
  }
}

@media only screen and (max-width: $media-m) {
  .vacancies {
    padding-bottom: em(100, $fz);

    &__wrapper {
      flex-direction: column;
      align-items: stretch;
    }

    &__list {
      margin-top: em(30, $fz);
      max-width: 100%;
    }
  }

  .dropdown {
    padding: em(30, $fz);

    &__col {
      margin-right: em(20, $fz);
    }
  }
}

@media only screen and (max-width: $media-s) {
  .vacancies {
    padding-bottom: em(80, $fz);
  }
}

@media only screen and (max-width: $media-xs) {
  .vacancies-item {
    margin-bottom: em(15, $fz);

    &:last-child {
      margin-bottom: 0;
    }

    &__row {
      padding: 0;
    }

    &__name {
      $fz: 14;
      font-size: em($fz, $fz_base);
    }

    &__btn::before {
      $fz: 18;
      font-size: em($fz, $fz_base);
    }

    &__dropdown {
      flex-direction: column;
    }
  }

  .dropdown {
    &__title {
      $fz: 14;
      font-size: em($fz, $fz_base);
      margin-bottom: em(20, $fz);
    }

    &__desc {
      $fz: 14;
      font-size: em($fz, $fz_base);
    }

    &__col {
      margin-bottom: em(20,$fz);

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}


