.about {
  padding-bottom: em(350, $fz);

  &__wrapper {
    margin-bottom: em(200, $fz);
  }

  &__achievements {
    display: flex;
    justify-content: space-between;
  }

  &__progress {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__number {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    $fz: 117;
    font-size: em($fz, $fz_base);
    font-weight: 700;

    &--plus::after {
      content: '+';
    }
  }

  &__measure {
    $fz: 18;
    font-size: em($fz, $fz_base);
    font-weight: 700;
    max-width: em(150, $fz);
    text-align: center;
    padding-top: em(155, $fz);
  }
}

.section {
  &__wrapper {
    display: flex;
    align-items: flex-start;
  }

  &__title {
    $fz: 48;
    font-size: em($fz, $fz_base);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin: em(0 20 0 10, $fz);
    white-space: nowrap;
  }

  &__slashes {
    $fz: 48;
    height: em(34, $fz);
    margin-right: em(5, $fz);
  }

  &__slash {
    $fz: 48;
    width: em(10, $fz);
    background-color: hsl(0, 0%, 100%);
    margin-right: em(10, $fz);
  }

  &__desc {
    margin-left: auto;
    display: flex;
    flex-direction: column;
    max-width: em(1055, $fz);
  }

  &__text {
    $fz: 16;
    font-size: em($fz, $fz_base);
    margin-bottom: em(25, $fz);
    line-height: 1.4;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

@media only screen and (max-width: $media-xl) {
  .about {
    padding-bottom: em(200, $fz);

    &__wrapper {
      margin-bottom: em(120, $fz);
    }

    &__achievements {
      flex-wrap: wrap;
      justify-content: center;
    }

    &__progress {
      width: 30%;
      margin-bottom: em(40, $fz);

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__number {
      $fz: 100;
      font-size: em($fz, $fz_base);
    }

    &__measure {
      $fz: 18;
      padding-top: em(130, $fz);
    }
  }
}

@media only screen and (max-width: $media-l) {
  .about {
    padding-bottom: em(150, $fz);

    &__wrapper {
      margin-bottom: em(80, $fz);
    }

    &__number {
      $fz: 80;
      font-size: em($fz, $fz_base);
    }

    &__measure {
      $fz: 18;
      padding-top: em(100, $fz);
    }
  }
  .section {
    &__title {
      $fz: 40;
      font-size: em($fz, $fz_base);
    }
  }
}

@media only screen and (max-width: $media-m) {
  .about {
    padding-bottom: em(100, $fz);

    &__wrapper {
      margin-bottom: em(50, $fz);
    }
  }

  .section {
    &__title {
      $fz: 40;
      font-size: em($fz, $fz_base);
    }

    &__text {
      $fz: 16;
      margin-bottom: em(15, $fz);
    }
  }
}

@media only screen and (max-width: $media-s) {
  .about {
    padding-bottom: em(80, $fz);

    &__wrapper {
      margin-bottom: em(30, $fz);
    }

    &__achievements {
      justify-content: flex-start;
    }

    &__progress {
      width: 50%;
    }
  }

  .section {
    &__title {
      $fz: 34;
      font-size: em($fz, $fz_base);
    }
  }
}

@media only screen and (max-width: $media-xs) {
  .about {

    &__number {
      $fz: 48;
      font-size: em($fz, $fz_base);
    }

    &__measure {
      $fz: 14;
      font-size: em($fz, $fz_base);
      padding-top: em(60, $fz);
    }

    &__wrapper {
      margin-bottom: em(40, $fz);

      .section__text {
        &:nth-child(2),
        &:last-child {
          display: none;
        }
      }
    }
  }

  .section {

    &__wrapper {
      flex-direction: column;
    }

    &__title {
      $fz: 24;
      font-size: em($fz, $fz_base);
      margin-bottom: em(10, $fz);
    }

    &__text {
      $fz: 14;
      font-size: em($fz, $fz_base);
      margin-bottom: 0;
    }
  }
}


